import React from 'react';
import PropTypes from 'prop-types';

import BaseCallToActionCard from '@components/BaseCallToActionCard';

import Form from './NewsletterCallToActionForm';

const submitForm = async (data, { reject, resolve }) => {
  try {
    const res = await fetch(
      'https://api.encharge.io/v1/forms/36b08134-e6e8-4634-a6c5-c453bfbe586f/submission/plain',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          ...data,
        }).toString(),
      },
    );

    if (res && res.ok) {
      resolve();
    } else {
      reject();
    }
  } catch {
    reject();
  }
};

const NewsletterCallToActionCard = ({
  claim = 'Sign up to hear more from Cloudsmith',
}) => {
  return (
    <BaseCallToActionCard
      actions={<Form submitForm={submitForm} />}
      claim={claim}
    />
  );
};

NewsletterCallToActionCard.propTypes = {
  claim: PropTypes.string,
};

export default NewsletterCallToActionCard;
