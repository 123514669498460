import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import BlogCard from '@components/blog/BlogCard';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './BlogListing.module.css';

import siteInformation from '@content/default.json';

const { blogPath } = siteInformation;

const BlogListing = ({
  posts,
  className,
  offset = 0,
  hasHero = false,
  title,
}) => {
  return (
    <div className={cn(styles.root, className)}>
      {title && (
        <>
          <h2 className={cn(styles.title, textStyles.headlineM)}>{title}</h2>
        </>
      )}
      <div
        className={cn(styles.grid, {
          [styles.hasHero]: hasHero,
        })}
      >
        {posts.length == 0 && <p>No blog posts available</p>}
        {posts.map((post, index) => {
          if (index > offset - 1) {
            return (
              <BlogCard
                key={post.id}
                title={post.title}
                excerpt={
                  post.custom_excerpt ? post.custom_excerpt : post.excerpt
                }
                image={
                  post.feature_image
                    ? {
                        src: post.feature_image,
                        alt: post.feature_image_alt
                          ? post.feature_image_alt
                          : '',
                      }
                    : null
                }
                href={`${blogPath}/${post.slug}`}
                publishedDate={post.published_at}
                tag={post.primary_tag?.name}
                tagSlug={post.primary_tag?.slug}
                readingTime={post.reading_time}
                featured={post.featured}
                isHero={hasHero && index === 0}
                authors={post.authors}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

BlogListing.propTypes = {
  className: PropTypes.string,
  hasHero: PropTypes.bool,
  offset: PropTypes.number,
  posts: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default BlogListing;
