import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useStateMachine from '@cassiozen/usestatemachine';
import { useForm } from 'react-hook-form';

import { trackAnalyticsEvent } from '@util/analytics';

import Button from '@components/Button';
import { TextInput } from '@components/Inputs';

import textStyles from '@styles/textStyles.module.css';
import styles from './NewsletterCallToActionForm.module.css';

export const fields = {
  email: {
    label: 'Email Address',
    placeholder: 'Your work email',
    required: 'Your email is required',
    valid: 'Please enter a valid email address',
  },
};

const NewsletterCallToActionForm = ({ submitForm }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [formMachineState, send] = useStateMachine({
    initial: 'idle',
    states: {
      idle: {
        on: { SUBMIT: 'sending' },
      },
      sending: {
        on: { FAIL: 'error', DONE: 'success' },
        effect({ event, send }) {
          submitForm(event.data, {
            resolve: () => send('DONE'),
            reject: () => send('FAIL'),
          });
        },
      },
      error: {
        effect() {
          trackAnalyticsEvent('newsletterFormError');
        },
      },
      success: {
        effect() {
          trackAnalyticsEvent('newsletterFormSuccess');
        },
      },
    },
  });

  const isSending = useMemo(
    () => formMachineState.value === 'sending',
    [formMachineState],
  );
  const showSuccess = useMemo(
    () => formMachineState.value === 'success',
    [formMachineState],
  );
  const showError = useMemo(
    () => formMachineState.value === 'error',
    [formMachineState],
  );
  const showForm = !showSuccess && !showError;

  return (
    <form
      className={styles.root}
      onSubmit={handleSubmit((data) => {
        send({ type: 'SUBMIT', data });
      })}
    >
      {showForm && (
        <>
          <TextInput
            placeholder={fields.email.placeholder}
            inputClassName={styles.input}
            {...register('email', {
              required: fields.email.required,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: fields.email.valid,
              },
            })}
            errors={errors.email}
          />
          <Button
            colorScheme="text"
            size="small"
            type="submit"
            disabled={isSending}
          >
            Sign Up
          </Button>
        </>
      )}
      {showSuccess && (
        <p className={textStyles.bodyL}>
          Thanks, we have sent you a confirmation email.
        </p>
      )}
      {showError && (
        <p className={textStyles.bodyL}>Sorry, something went wrong</p>
      )}
    </form>
  );
};

NewsletterCallToActionForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
};

export default NewsletterCallToActionForm;
