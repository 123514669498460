import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { readableDate } from '@util/date';

import Image from '@components/Image';
import Link from '@components/Link';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './BlogCard.module.css';

import siteInformation from '@content/default.json';

const { blogPath } = siteInformation;

const BlogCard = ({
  excerpt,
  href,
  image = null,
  isHero = false,
  publishedDate,
  readingTime,
  tag,
  tagSlug,
  textSize = 'small',
  title,
  authors,
}) => {
  return (
    <article
      className={cn(styles.root, {
        [styles.isHero]: isHero,
      })}
    >
      <Link prefetch={false} href={href} className={cn(styles.coverWrapper)}>
        {image && (
          <Image
            src={image.src}
            alt={image.alt}
            fill
            sizes="(min-width: 960px) 33vw, 100vw"
            className={styles.cover}
          />
        )}
      </Link>

      <div className={cn(styles.details)}>
        <div className={cn(styles.meta)}>
          <div className={cn(styles.metaList)}>
            <div
              className={cn(styles.metaListItem, styles.date, styles.nowrap)}
            >
              {readableDate(publishedDate)}
            </div>
            {tag && (
              <>
                <Link
                  href={`${blogPath}/tag/${tagSlug}`}
                  className={cn(styles.metaLink, styles.metaListItem)}
                >
                  {tag}
                </Link>
              </>
            )}
          </div>
          <div className={cn(styles.metaReadingTime, styles.nowrap)}>
            {readingTime} min read
          </div>
        </div>

        <Link prefetch={false} href={href} className={cn(styles.headlineLink)}>
          <h3
            className={cn({
              [textStyles.headlineXS]: textSize === 'xsmall',
              [textStyles.headlineS]: textSize === 'small',
              [textStyles.headlineM]: textSize === 'medium',
              [textStyles.headlineM]: isHero,
            })}
          >
            {title}
          </h3>
        </Link>
        {isHero && excerpt && <div className={styles.excerpt}>{excerpt}</div>}
        {authors && authors.length > 0 && (
          <div className={styles.authors}>
            {authors.map((author, index) => (
              <div key={index} className={styles.authorImage}>
                {author.profile_image && (
                  <Image
                    className={styles.authorImage}
                    src={author.profile_image}
                    alt={`Picture of ${author.name}`}
                    width={34}
                    height={34}
                  />
                )}
              </div>
            ))}
            <span className={styles.authorName}>
              {authors.map((author, index) => (
                <span key={author.id}>
                  {author.name}
                  {index < authors.length - 2
                    ? ', '
                    : index === authors.length - 2
                    ? ' and '
                    : ''}
                </span>
              ))}
            </span>
          </div>
        )}
      </div>
    </article>
  );
};

BlogCard.propTypes = {
  authors: PropTypes.array,
  excerpt: PropTypes.string,
  href: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  isHero: PropTypes.bool,
  publishedDate: PropTypes.string.isRequired,
  readingTime: PropTypes.number.isRequired,
  tag: PropTypes.string,
  tagSlug: PropTypes.string,
  textSize: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  title: PropTypes.string.isRequired,
};

export default BlogCard;
